export default function contatochange() {
	const headersForm = document.querySelectorAll('[data-form-header]')
	const formsContato = document.querySelectorAll('[data-form-contato]')

	const changeHeaderForm = () => {
		headersForm.forEach((header) => {
			header.addEventListener('click', (e) => {
				headersForm.forEach((header) => {
					header.classList.remove('header-active')
				})
				formsContato.forEach((form) => {
					form.classList.add('hidden')
					if (form.dataset.formContato === header.dataset.formHeader) {
						header.classList.add('header-active')
						form.classList.remove('hidden')
					}
				})
			})
		})
	}

	changeHeaderForm()
}
